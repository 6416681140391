import React, {useState, useEffect, useReducer} from "react";
import API from "../Network/API";
import {Link, useParams} from "react-router-dom";
import { ACTION_TYPES } from "../Network/postActionTypes";
import { INITIAL_STATE, postReducer } from "../Network/PostReducer";
import { Video_INITIAL_STATE, SuggestVideoReducer } from "../Network/SuggestVideoReducer";
import VideoJS from "../Props/VideoJS";
import {toast} from "react-toastify";
import PostLoveModal from "react-bootstrap/Modal";
import UserList from "../Props/UserList";
import PostShareModal from "../Props/PostShareModal";
import SuggestVideo from "../Props/SuggestVideo";
import { useDispatch, useSelector } from "react-redux";
import { SINGLE_POST_ADD_REPLY, SINGLE_POST_DELETE_REPLY, SINGLE_POST_FETCH_COMMENT_ADDON, SINGLE_POST_FETCH_ERROR, SINGLE_POST_FETCH_START, SINGLE_POST_FETCH_SUCCESS } from "../Reducer/singlePostReducer";
import { ADD_REPLY, DELETE_REPLY, POST_FETCH_COMMENT_ADDON } from "../Reducer/postReducer";
import DivPlaceholder from "../Network/DivPlaceholder";
import { FPOST_FETCH_COMMENT_ADDON } from "../Reducer/friendProfileReducer";
import { useTranslation } from "react-i18next";

export default function SingleVideo() {

    const {t} = useTranslation()
    const [isLoading, setLoading] = useState(false);
    let { id } = useParams();
    const dispatch=useDispatch();

    const [state_video, dispatch_videos] = useReducer(SuggestVideoReducer, Video_INITIAL_STATE);

    const myArr = JSON.parse(localStorage.getItem('user_data'));
    const [love_react, setLoveReact] = useState(false);
    const [comment_love_react, setCommentLoveReact] = useState(false);
    const [reply_comment_delete, setReplyCommentDelete] = useState(false);
    const [reply_comment_id, setReplyCommentID] = useState(false);
    const [comment_love_react_add, setCommentLoveReactAdd] = useState(0);
    const [delete_post, setDeletePost] = useState(false);
    const [write_comment, setWriteComment] = useState(null);
    const [reply_comment, setReplyComment] = useState(null);
    const [share_comment, setShareComment] = useState(null);
    const [p_id_comment, setPIDComment] = useState(null);
    const [p_id_like, setPIDLike] = useState(0);
    const [p_id_com, setPIDCom] = useState(0);
    const [set_like, setLike] = useState(0);
    const [last_comments, setLastComments] = useState([]);
    const [set_comment, setComment] = useState(0);
    const [add_reply_comment, setAddReplyComment1] = useState(0);
    const [postId, setPostId] = useState(0);
    const [modalDialog, setModalDialog] = useState(false);
    const [modalDialogLoved, setModalDialogLoved] = useState(false);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const [show, setShow] = useState(false);
    const [show_comment_box, setShowCommentBox] = useState(false);
    const [category_id, setCategoryId] = useState(0);
    const [report_note, setReportNote] = useState(0);
    const [categories, setCategories] = useState([]);
    const [reply_com_id, setReplyComID] = useState(0);
    const [comment_id, setCommentID] = useState(0);
    const [is_com_delete, setISComDelete] = useState(false);
    const [loadPostLove, setLoadPostLove] = useState(false);
    const [post_loved, setPostLoved] = useState([]);
    const [videos, setVideos] = useState([]);
    const toggleModal = () => {
        setModalDialog(!modalDialog)
    };

    const toggleModalLoved = () => {
        setModalDialogLoved(!modalDialogLoved)
    };

    const toggleModalSharePost = () => {
        setPostShareModalDialog(!post_modalDialog)
    };

    const postLike = (post_id) => {
        setLoading(true)
        setLoveReact(true)
        setLike(1)
        setPIDLike(post_id)
        API.post("/post_likes", {
            post_id: post_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            setLoveReact(true)
            if (response.data.success === true) {
                // toast.success('Post loved Successfully');
            } else {
                setLoveReact(false)
            }
        });
    }

    const toggleReply = (com_id) => {
        setShow(!show)
        setPIDCom(com_id)
    };

    const toggleComment = (post_id) => {
        setShowCommentBox(!show_comment_box)
        setPostId(post_id)
    };


    const commentLike = (post_id, comment_id) => {
        setLoading(true)
        setCommentLoveReact(true)
        setPIDCom(comment_id)
        setCommentLoveReactAdd(1)
        API.post("/comment_like", {
            post_id: post_id,
            comment_id: comment_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
            }
        });
    }

    const commentsReplyDelete = (post_id, comment_id,comment_reply_id) => {
        setLoading(true)
        API.delete("/reply_comment_delete/" + comment_id).then((response) => {
            if (response.data.success === true) {
                setLoading(true)
                setReplyCommentDelete(true)
                setReplyCommentID(comment_id)
                toast.success('Comment deleted');
                const payload={
                    post_id:post_id,
                    comment_id:comment_id,
                    comment_reply_id:comment_reply_id,
                }
                
                dispatch(DELETE_REPLY(payload))
                dispatch(SINGLE_POST_DELETE_REPLY(payload))
            }
        });
    }

    const handleReplyMessage = (p_id, cm_id) => {
        setLoading(true)
        setReplyComID(cm_id)
        API.post("/comment_reply", {
            post_id: p_id,
            comment_id: cm_id,
            comment: reply_comment,
        }).then((response) => {
            setLoading(false)
            setReplyComment('')
            if (response.data.success === true) {
                dispatch(ADD_REPLY(response.data.data))
                dispatch(SINGLE_POST_ADD_REPLY(response.data.data))
                // toast.success('Reply sent');
            }
        });
    }

    const submit_comment = (p_id) => {
        if (write_comment === null) {
            toast.success('Enter something!');
            return false;
        }
        setLoading(true)
        setComment(1)
        API.post("/post_comments", {
            post_id: p_id,
            comment: write_comment,
        }).then((response) => {
            setLoading(false)
            setWriteComment("")
            if (response.data.success === true) {
                dispatch(POST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(FPOST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(SINGLE_POST_FETCH_COMMENT_ADDON(response.data.data))
                setPostId(p_id)
                // toast.success('Comment sent successfully');
            }
        });

    }

    const postDelete = (post_id) => {
        setLoading(true)
        API.delete("/posts/" + post_id).then((response) => {
            if (response.data.success === true) {
                setLoading(false)
                setDeletePost(true)
                setPIDLike(post_id)
                toast.success('Post deleted');
            }
        });
    }

    const get_post_report = (post_id) => {
        toggleModal();
        API.get('post_report/' + post_id)
            .then((response) => {
                setCategories(response.data.data.categories)
                toggleModal();
            }).catch((error) => {
        });
    }

    const save_post = (p_id) => {
        setLoading(true)
        API.post("/save_friend_post", {
            post_id: p_id,
        }).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                toast.success('Video Saved');
            }
        });
    }

    const commentsDelete = (comments_id,post_id) => {
        setLoading(true)
        API.delete("/comment_delete/" + comments_id).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                setISComDelete(true)
                setCommentID(comments_id)
                setPostId(post_id)
                setComment(-1)
                toast.success('Comment deleted');
            }
        });
    }

    const share_post = (p_id) => {
        setPostId(p_id);
        toggleModalSharePost()
    }

    const send_report = (post_id) => {
        setLoading(true);
        API.post("/save_report", {
            post_id: post_id,
            category_id:  parseInt(category_id),
            note: report_note,
        }).then((response) => {
            setLoading(false);
            if (response.data.success === true) {
                toast.success('Post reported');
                toggleModal();
            }
        }).catch((error) => {
            setLoading(false);
            toggleModal();
        });
    }

    let optionItems = categories?.map(category =>
        <option key={category.id} value={category.id}>{category.name}</option>
    );

    const love_report = (post_id) => {
        setLoadPostLove(true);
        API.get('post_love_details/' + post_id)
            .then((response) => {
                setPostLoved(response.data.data.data)
                toggleModalLoved();
            }).catch((error) => {
        });
    }


    useEffect(() => {
        
        dispatch(SINGLE_POST_FETCH_START());
        API.get(`/get_post_comments/${id}`).then(response => {
            dispatch(SINGLE_POST_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(SINGLE_POST_FETCH_ERROR());
        });

        API.get(`/get_reels?sl=${id}`).then(response => {
            dispatch_videos({ type: ACTION_TYPES.FETCH_SUCCESS, payload: response.data.data.data });
        }).catch((error) => {
            dispatch_videos({ type: ACTION_TYPES.FETCH_ERROR });
        });

    }, [id]);

    const state = useSelector((state) => state.singlePostReducer)
    const post=state?.post_data
    
    return(

      <>
            {state.loading?
            <>
            <div className="row d-flex justify-content-center">
              <div className="col-md-9">
            <DivPlaceholder key='1' backgroundColor="#ffffff"
                                            foregroundColor="#ecebeb"/>
                                            </div>
                                            </div>
            </>
            :
          <div className="row d-flex justify-content-center">
              <div className="col-md-9">
              <div className="story-postbox news-feed-area">
                  <div className="row justify-content-md-center">
                      <div className="col-md-12">
                          <div className="news-feed news-feed-post bg-white mb-3 shadow-sm">
                              <div className="post-body">
                                  <div className="post-image mb-2">

                                      {!state.loading && post?.videos?.map((getVideo, i) => (
                                          <VideoJS
                                            key={i}
                                            options={{
                                                autoplay: true,
                                                muted: false,
                                                controls: true,
                                                preload: 'metadata',
                                                height:'550',
                                                poster: getVideo.thumbnail,
                                                sources: [{
                                                src: getVideo.video_stream,
                                                type: 'video/mp4'
                                                }]
                                            }}
                                            enableScrollPause={false}
                                            />
                                      ))}
                                  </div>
                                  <div className="video-info">
                                      <div className="d-flex  align-items-start">
                                          <div className="video-user-container flex-shrink-0">
                                              <Link to={`/contact/${post?.user_id}`}>
                                              <div className="user-picture-container rounded-circle video-user-35" style={{backgroundImage: `url(${post?.avatar})`}} />
                                              </Link>
                                          </div>
                                          <div className="text flex-shrink-1">
                                              <h6 className="ms-2 me-2">
                                                  {post?.details ? post?.details : 'Untitled'}
                                              </h6>
                                              <div className="video-caption ms-2 me-2">
                                                  <p><span>{post?.post_by}</span> <span className="circle"><i className="fa-solid fa-circle" /></span>  <span>{post?.post_time}</span></p>
                                              </div>
                                          </div>
                                          <div className="global-dropdown flex-shrink-end">
                                              <div className="dropdown">
                                                  <button className="dropdown-toggle dropdown-bg-transparent video-drop" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical" /></button>
                                                  <ul className="dropdown-menu" style={{}}>
                                                      <li><a className="dropdown-item d-flex align-items-center clickable" onClick={() => save_post(post?.id)}><i
                                                          className="fa-solid fa-save"/> {t('Save Post')}</a></li>
                                                      <li><a className="dropdown-item d-flex align-items-center clickable" onClick={() => get_post_report(post?.id)}><i
                                                          className="fa-solid fa-eye-slash"/> {t('Report Post')}</a></li>

                                                      {post?.user_id===myArr['id']?
                                                          <li><a className="dropdown-item d-flex align-items-center clickable" onClick={() =>postDelete(post?.id)}><i
                                                              className="fa-solid fa-trash-can"/> {t('Delete')} </a></li>
                                                          :''}
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="d-flex justify-content-between video-meta-warp">
                                      <ul className="post-meta-wrap d-flex justify-content-between align-items-center m-0 p-0">
                                          <li className="post-react">
                                              {post?.is_like ?
                                                  <><i className="fa-solid fa-heart i-loved"/><span>{t('Love')}</span></>
                                                  : <a className={'clickable'} onClick={() => postLike(post?.id)}><i
                                                      className={`fa-solid fa-heart ${love_react && p_id_like === post?.id ? 'i-loved' : ''}`}/><span>{t('Love')}</span></a>}
                                          </li>
                                          <li className="post-react">
                                              <a className={'clickable'} onClick={() => toggleComment(post?.id)}><i
                                                  className="fa-solid fa-comment"/><span>{t('Comments')}</span></a>
                                          </li>
                                          <li className="post-react">
                                              <a className={'clickable'} onClick={() => share_post(post?.id)}><i className="fa-solid fa-share-nodes"/><span>{t('Share')}</span></a>
                                          </li>
                                      </ul>
                                      <div className="video-view-like-count">
                                          <span onClick={()=>love_report(post?.id)} className={`clickable`}>{post?.totalLikes+set_like} {t('Loved')}</span>
                                          <span className="ms-1 me-1">|</span>
                                          <span>{post?.totalComments+set_comment} {t('Comments')}</span>
                                          <span className="ms-1 me-1">|</span>
                                          <span>0 {t('Share')}</span>
                                      </div>
                                  </div>

                                  {post?.last_comments?.length > 0 ?
                                      <>
                                          <div style={{ height:'10px' }}></div>
                                          {post?.last_comments?.map((getcomment, i) => (
                                              <>
                                                  <div key={i}
                                                       className={`post-comment-list ${is_com_delete && comment_id===getcomment.id?'d-none':''} `}
                                                       id={"comments_" + getcomment.post_id}>
                                                      <div className="comment-list d-flex justify-content-between">
                                                          <div className="comment-image">
                                                              <Link to={`/contact/${getcomment.comment_user_id}`}>
                                                                  <div className="user-picture-container rounded-circle"
                                                                       style={{backgroundImage: `url(${getcomment.avatar})`}}/>
                                                              </Link>
                                                          </div>
                                                          <div className="comment-list-meta">
                                                              <div className="comment-info shadow-sm">
                                                                  <div className="d-flex justify-content-between">
                                                                      <div className="name">
                                                                          <Link to={`/contact/${getcomment.comment_user_id}`}>{getcomment.comment_by}</Link>
                                                                          {getcomment.is_online?<i className="fa-solid fa-circle"/>:''}
                                                                      </div>
                                                                      <a className="time">{getcomment.comment_time}</a>
                                                                  </div>
                                                                  <p>{getcomment.comment}</p>
                                                              </div>
                                                              <ul className="comment-react">
                                                                  <li className="post-react">
                                                                      {getcomment.is_like ?
                                                                          <a className={'clickable'}><i
                                                                              className="fa-solid fa-heart"/><span>Love</span>
                                                                              <span
                                                                                  className="number"> {getcomment.total_likes} </span></a>
                                                                          :
                                                                          <a className={'clickable'}
                                                                             onClick={() => commentLike(post?.id, getcomment.id)}><i
                                                                              className={`fa-solid fa-heart ${p_id_com === getcomment.id && comment_love_react ? 'i-loved' : 'text-dark'} `}/><span>{t('Love')}</span>
                                                                              <span
                                                                                  className="number"> {p_id_com === getcomment.id && comment_love_react ? getcomment.total_likes + comment_love_react_add : getcomment.total_likes>0?getcomment.total_likes:''} </span></a>
                                                                      }

                                                                  </li>
                                                                  <li className="post-comment">
                                                                      <a className={'clickable'}
                                                                         onClick={() => toggleReply(getcomment.id)}><i
                                                                          className="fa-solid fa-comment"/>{t('Reply')}{getcomment.comment_reply?.length>0?`(${getcomment.comment_reply?.length})`:''} <span
                                                                          className="number"> </span></a>
                                                                  </li>
                                                                  <li className="post-comment-replay-icon">
                                                                      <a href="javascript:void(0)"><i className="fa-solid fa-angle-down"/></a>
                                                                  </li>
                                                              </ul>
                                                          </div>
                                                          {getcomment.is_deletable === 1 ?
                                                              <div className="comment-button">
                                                                  <div className="dropdown">
                                                                      <button className="dropdown-toggle" type="button"
                                                                              data-bs-toggle="dropdown"
                                                                              aria-haspopup="true" aria-expanded="false"><i
                                                                          className="fa-solid fa-ellipsis-vertical"/></button>
                                                                      <ul className="dropdown-menu">

                                                                          <li><a className="dropdown-item d-flex align-items-center clickable"
                                                                                 onClick={() => commentsDelete(getcomment.id,post?.id)}><i
                                                                              className="fa-solid fa-trash-can"/> {t('Delete')} </a></li>
                                                                      </ul>
                                                                  </div>
                                                              </div>
                                                              :''}
                                                      </div>
                                                  </div>

                                                  {getcomment.comment_reply?.length > 0 ?
                                                      <>
                                                          {getcomment.comment_reply?.map((reply, i) => (
                                                              <>
                                                                  <div key={Math.random()} id={`reply${reply.id}`}
                                                                       className={`post-comment-list post-comment-replay ${p_id_com === getcomment.id && show ? '' : 'd-none'} ${reply_comment_delete && reply_comment_id === reply.id ? 'd-none' : ''}`}>
                                                                      <div className="comment-list d-flex justify-content-between">
                                                                          <div className="comment-image">
                                                                              <Link to={`/contact/${reply.comment_user_id}`}>
                                                                                  <div
                                                                                      className="user-picture-container rounded-circle"
                                                                                      style={{backgroundImage: `url(${reply.avatar})`}}/>
                                                                              </Link>
                                                                          </div>
                                                                          <div className="comment-list-meta">
                                                                              <div className="comment-info shadow-sm">
                                                                                  <div className="d-flex justify-content-between">
                                                                                      <div className="name">
                                                                                          <Link
                                                                                              to={`/contact/${reply.comment_user_id}`}>{reply.comment_by}</Link>
                                                                                          {reply.is_online?<i className="fa-solid fa-circle"/>:''}
                                                                                      </div>
                                                                                      <span className="time"
                                                                                      >{reply.comment_time}</span>
                                                                                  </div>
                                                                                  <p>{reply.comment}</p>
                                                                              </div>
                                                                          </div>
                                                                          {reply.is_deletable ?
                                                                              <div className="comment-button">
                                                                                  <div className="dropdown">
                                                                                      <button className="dropdown-toggle"
                                                                                              type="button"
                                                                                              data-bs-toggle="dropdown"
                                                                                              aria-haspopup="true"
                                                                                              aria-expanded="false">
                                                                                          <i
                                                                                              className="fa-solid fa-ellipsis-vertical"/>
                                                                                      </button>
                                                                                      <ul className="dropdown-menu">

                                                                                          <li><span
                                                                                              onClick={() => commentsReplyDelete(reply.post_id,reply.comment_id,reply.id)}
                                                                                              className="dropdown-item d-flex align-items-center clickable"
                                                                                          ><i
                                                                                              className="fa-solid fa-trash-can"/> {t('Delete')}</span>
                                                                                          </li>
                                                                                      </ul>
                                                                                  </div>
                                                                              </div> : ""
                                                                          }
                                                                      </div>
                                                                  </div>


                                                              </>

                                                          ))}


                                                      </> : ""
                                                  }
                                                  <div
                                                      className={`post-comment-list post-comment-replay  ${p_id_com === getcomment.id && show ? '' : 'd-none'}`}>
                                                      <form className="post-footer d-flex justify-content-between">
                                                          <div className="footer-image">
                                                              <Link to="javascript:void(0)">
                                                                  <div
                                                                      className="user-picture-container rounded-circle"
                                                                      style={{backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})`}}/>
                                                              </Link>
                                                          </div>
                                                          <div className="form-group post-footer-comment-form">
                                                  <textarea name="message" className="form-control"
                                                            placeholder="Write a comment..."
                                                            defaultValue={'@' + getcomment.comment_by + ' '} value={reply_comment}  onChange={(e) => setReplyComment(e.target.value)}/>
                                                              <label className="post-footer-comment-icon">
                                                                  {/* <a href="javascript:void(0)"><i
                                                                      className="fa-regular fa-face-smile"/></a> */}
                                                                  <span className="horizontal-divided"/>
                                                                  <button type="button" onClick={()=>handleReplyMessage(post?.id,getcomment.id)} className="post-footer-comment-submit">Post  {isLoading && reply_com_id===getcomment.id ? (
                                                                      <span
                                                                          className="spinner-border spinner-border-sm ml-5"
                                                                          role="status"
                                                                          aria-hidden="true"
                                                                      ></span>
                                                                  ) : (
                                                                      <span></span>
                                                                  )}
                                                                  </button>
                                                              </label>
                                                          </div>
                                                      </form>
                                                  </div>
                                                  {post?.last_comments?.length > 10 ?
                                                      <div className="view-comment-text">
                                                          <a href="javascript:void(0)">View more comments</a>
                                                      </div>
                                                      :''}
                                              </>
                                          ))}
                                      </>
                                      : <a className={`clickable ${postId === post?.id && show_comment_box ? 'd-none' : ''}`}
                                           style={{fontSize: '10px'}} onClick={() => toggleComment(post?.id)}>{t('Be the first to comment on this')}</a>
                                  }
                                  <div className={`post-footer d-flex justify-content-between `}>
                                      <div className="footer-image">
                                          <a href="javascript:void(0)">
                                              <div className="user-picture-container rounded-circle"
                                                   style={{backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})`}}/>
                                          </a>
                                      </div>
                                      <div className="form-group post-footer-comment-form">
                                            <textarea name="message" className="form-control" placeholder={t('Post your comments')}
                                                    value={write_comment} onChange={(e) => setWriteComment(e.target.value)}
                                            />
                                          <label className="post-footer-comment-icon">
                                              {/* <a href="javascript:void(0)"><i className="fa-regular fa-face-smile"/></a> */}
                                              <button type="button" onClick={()=>submit_comment(post?.id)} className="post-footer-comment-submit">{t('Post')}
                                              </button>
                                          </label>
                                      </div>
                                  </div>

                              </div>
                          </div>

                      </div>
                  </div>
              </div>
              <PostLoveModal
                  show={modalDialogLoved}
                  size=""
                  onHide={toggleModalLoved}
              >
                  <PostLoveModal.Header closeButton>
                      <div className="post-meta-react-count-love">
                          <a href="javascript:void(0)">
                              <i className="fa-solid fa-heart"/>
                              <span className="number">{post?.totalLikes}</span>
                          </a>
                      </div>

                  </PostLoveModal.Header>

                  <PostLoveModal.Body className={`custom-scroll pt-2`}>
                      <div className="recent-chat">
                          <ul className="list-group list-group-flush">
                              {post_loved?.map((user, index) => (
                                  <UserList user={user}/>
                              ))
                              }

                          </ul>
                      </div>
                  </PostLoveModal.Body>
              </PostLoveModal>
             
          </div>
          <PostShareModal isModalVisible={post_modalDialog} handleShowB={() => setPostShareModalDialog(false)} postId={post?.id} post_data={post}/>
          
          <div className="col-md-3 d-none d-sm-block ">
            <div className="sidebar-scroll">
              <div className="widget-area">
                  <div className="widget widget-banner mb-4">
                      <h4>{t('Suggested for you')}</h4>
                  </div>
                  {state_video.videos?.length>0?state_video.videos?.map((video, index) => (
                      <SuggestVideo data={video}/>
                      )):''}

              </div>
            </div>
          </div>
          </div>
}
          </>
    )

}