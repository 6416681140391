import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import API from "../../Network/API";
import { Link } from "react-router-dom";
import CreatePost from "../CreatePost";
import { Facebook } from "react-content-loader";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Placeholder1 from "../../Network/Placeholder1";
import { useDispatch, useSelector } from "react-redux";
import { FPOST_FETCH_ERROR, FPOST_FETCH_SUCCESS, FPOST_FETCH_SUCCESS_LOAD } from "../../Reducer/friendProfileReducer";
import CropEasy from "./crop/CropEasy";
import CoverCrop from "./crop/CoverCrop";
import { MY_PROFILE_FETCH_SUCCESS } from "../../Reducer/MyProfileSlice";
import PostNew from "../../Props/PostNew";
import PostViewProfile from "../Modal/PostViewProfile";
import ImageView from "../Modal/ImageView";
import { useTranslation } from "react-i18next";


export default function MyProfile() {
    const { t } = useTranslation()
    const selector_profile = useSelector((state) => state.myProfileReducer)
    const selector = useSelector((state) => state.friendProfileReducer)
    const postsData = selector?.post_data
    const personal_info = selector_profile?.personal_info
    const post_images = selector_profile?.post_images
    const [demo_data, setDatas] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }]);

    const [progress, setProgress] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [imgLoading, setImgLoading] = useState(false);
    const [coverLoading, setCoverLoading] = useState(false);
    const [isLoadingUp, setLoadingUp] = useState(false);
    const [modalDialog, setModalDialog] = useState(false);
    const fileInput = React.useRef();
    const [profileInput, setProfileInput] = useState({});
    const [openCrop, setOpenCrop] = useState(false);
    const [openCropCover, setOpenCropCover] = useState(false);
    const [file, setFile] = useState(null);
    const [photoURL, setPhotoURL] = useState(null);
    const [coverURL, setCoverURL] = useState(null);
    const [coverFile, setCoverFile] = useState(null);
    const [page, setPage] = useState(1);

    const name = useRef(null);
    const bio = useRef(null);
    const company_name = useRef(null);
    const designation = useRef(null);
    const web_site = useRef(null);
    const city = useRef(null);
    const username = useRef(null);

    const [postView_modalDialog, setPostViewModalDialog] = useState(false);
    const [post_data, setPostData] = useState(null);
    const [post_search_Id, setPostId] = useState(0);
    const [image_index, setImageIndex] = useState(null);
    const [img_index, setImIndex] = useState(null);
    const [imageView_modalDialog, setImageViewModalDialog] = useState(false);
    const [image_array, setImageArray] = useState([]);

    const handleChange1 = ({ target }) => {
        const { name, value } = target;
    
        if (name === "name") {
            const isValid = /^[\p{L}\s]*$/u.test(value);
            if (isValid && value.length <= 30) {
                setProfileInput({ ...profileInput, [name]: value });
                document.getElementById('nameError').innerText = '';
            } else {
                if (!isValid) {
                    document.getElementById('nameError').innerText = 'Only letters and spaces are allowed.';
                }
                if (value.length > 30) {
                    document.getElementById('nameError').innerText = 'Maximum 30 characters allowed.';
                }
            }
        } else if (name === "company_name" || name === "designation" || name === "city") {
            if (value.length <= 40) {
                setProfileInput({ ...profileInput, [name]: value });
                const errorField = 
                    name === "company_name" ? "companyNameError" : 
                    name === "designation" ? "designationError" : 
                    "cityError";
                document.getElementById(errorField).innerText = '';
            } else {
                const errorField = 
                    name === "company_name" ? "companyNameError" : 
                    name === "designation" ? "designationError" : 
                    "cityError";
                document.getElementById(errorField).innerText = 'Maximum 40 characters allowed.';
            }
        
        } else if (name === "bio") {
            if (value.length <= 160) {
                setProfileInput({ ...profileInput, [name]: value });
                document.getElementById('bioError').innerText = '';
            } else {
                document.getElementById('bioError').innerText = 'Maximum 160 characters allowed.';
            }
        } else if (name === "web_site") {
            const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[^\s]*)?$/;
            if (urlRegex.test(value)) {
                setProfileInput({ ...profileInput, [name]: value });
                document.getElementById('websiteError').innerText = '';
            } else {
                document.getElementById('websiteError').innerText = 'Please enter a valid URL with a domain (e.g., .com, .org).';
            }
        } else {
            setProfileInput({ ...profileInput, [name]: value });
        }
    };

    const get_post_data = (value) => {
        setPostData(value);
        setPostId(value.id)
        // console.log(value.id);
    }

    const get_post_image_index = (value) => {
        setImageIndex(value);
    }

    const togglePostView = (value) => {
        setPostViewModalDialog(!postView_modalDialog);
    }

    const dispatch = useDispatch();

    // const handleChange = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     let data = {};
    //     data[name] = value;
    //     setProfile(data);
    // };

    const toggleModalCrop = () => {
        setOpenCrop(!openCrop)
    }



    const handleChangePhoto = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file)
            setPhotoURL(URL.createObjectURL(file))
            toggleModalCrop()
        }
    };

    useEffect(() => {
        setPhotoURL(personal_info?.avatar)
        API.get(`/my_profile`).then(response => {
            dispatch(FPOST_FETCH_SUCCESS(response.data.data));
            dispatch(MY_PROFILE_FETCH_SUCCESS(response.data.data));
            setPhotoURL(personal_info?.avatar)
        }).catch((error) => {

        });

    }, []);

    const _logout = () => {
        API.get(`/logout`)
            .then(res => {
                if (res.data.success === true) {
                    localStorage.clear();

                }
            })
        localStorage.clear();
        window.location.href = '/login';
    }

    const fileSelectedHandler = (e) => {
        onCreateStory(e.target.files[0]);
    }

    const onCreateStory = (fileImage) => {
        setLoading(true);
        const fd = new FormData();
        if (fileImage != null) {
            fd.append('image', fileImage, fileImage.name);
        } else {
            return false;
        }
        API.post("/stories", fd, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoading(false);
            if (response.data.success === true) {
                toast.success(response.data.message);
            } else if (response.data.success === false) {

            }
        })
            .catch((err) => {

            });
    };

    const coverSelectedHandler = (e) => {
        _profile_cover(e.target.files[0]);
    }

    const profileImageSelectedHandler = (e) => {
        _profile_image_update(e.target.files[0]);
    }

    const changePhotoUrl = (photoURL) => {
        setPhotoURL(photoURL)
    };

    const _profile_image_update = (file) => {
        setImgLoading(true);
        const fd = new FormData();
        if (file != null) {
            fd.append('image', file, file.name);
        } else {
            return false;
        }

        API.post("/change_profile_picture", fd, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setImgLoading(false);
            if (response.data.success === true) {
                //  console.log(response.data.data.avatar);
                localStorage.setItem("profile_img", response.data.data.avatar);
                localStorage.setItem("profile_name", response.data.data.name);
                //  localStorage.setItem("user_data", JSON.stringify(response.data.data));
                window.location = "/me";

            } else if (response.data.success === false) {
                // console.log(response.data);
            }
        }).catch((err) => {
            setImgLoading(false);
        });
    };


    const toggleModalCover = () => {
        setOpenCropCover(!openCropCover)
    }

    const handleChangeCover = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCoverFile(file)
            setCoverURL(URL.createObjectURL(file))
            setOpenCropCover(true)
        }
    };

    const _profile_cover = (fileImage) => {
        setCoverLoading(true);
        const fd = new FormData();
        if (fileImage != null) {
            fd.append('image', fileImage, fileImage.name);
        } else {
            return false;
        }

        API.post("/change_cover_picture", fd, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setCoverLoading(false);
            if (response.data.success === true) {
                window.location = "/me";
            } else if (response.data.success === false) {

            }
        }).catch((err) => {
            setCoverLoading(false);
        });
    };

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    const _profile_update = () => {

        setLoadingUp(true)
        console.log(profileInput)
        const data = {
            name: profileInput.name,
            designation: profileInput.designation,
            company_name: profileInput.company_name,
            bio: profileInput.bio,
            web_site: profileInput.web_site,
            city: profileInput.city,
            username: profileInput.username ?? '',
        }
        API.post("/profileUpdate", data).then((response) => {
            setLoadingUp(false)
            if (response.data.success === true) {
                toast.success('Profile update successfully');
                window.location = "/me";
            }
        });
    }

    function fetchRepositoriesNext() {
        setLoading(true)
        API.get(`/v1/profile_posts?page=${page + 1}`)
            .then(response => {
                setPage((page + 1))
                setLoading(false)
                dispatch(FPOST_FETCH_SUCCESS_LOAD(response.data.data));
            }).catch((error) => {
                dispatch(FPOST_FETCH_ERROR());
                setLoading(false)
            })
    }


    const toggleImageView = (value, imgindex) => {
        const img = value.map(v => v.image);
        setImageArray(img);
        setImIndex(imgindex);
        setImageViewModalDialog(!imageView_modalDialog);
    }

    const toggleSingleImageView = (value, imgindex) => {
        setImageArray([value]);
        setImIndex(imgindex);
        setImageViewModalDialog(!imageView_modalDialog);
    }


    return (
        <div className="story-postbox news-feed-area">
            <div className="row">
                <div className="user-profile">
                    <figure>
                        <div className="edit-pp clickable">
                            <label className="fileContainer">
                                {coverLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <i className="fas fa-camera me-1" />
                                )}

                                <span>{t('Edit Cover Photo')}</span>
                                {/* coverSelectedHandler */}

                                <input type="file" accept="image/*" onChange={handleChangeCover} />
                            </label>
                        </div>
                        <div className="profile-cover-image"
                            onClick={() => toggleSingleImageView(personal_info?.cover_image, 0)}
                            style={{ backgroundImage: `url(${personal_info?.cover_image ?? "https://b.connectsweb.com/img/default-cover.jpg"})` }}>
                        </div>

                    </figure>
                    <div className="profile-section">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="profile-author">
                                    <div className="profile-author-thumb">
                                        <div className="author-img"
                                            onClick={() => toggleSingleImageView(personal_info?.orginal_avatar, 0)}
                                            style={{ backgroundImage: `url(${personal_info?.orginal_avatar ?? "https://via.placeholder.com/50x50.png"})` }} />
                                        <div className="edit-dp">
                                            <label className="fileContainer">
                                                {imgLoading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm ml-5"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    <i className="fas fa-camera" />
                                                )}

                                                {/* onChange={profileImageSelectedHandler} */}
                                                <input type="file" accept="image/*"
                                                    onChange={handleChangePhoto} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="author-content">
                                        <Link className="h4 author-name" to={'#'}>{personal_info?.name}</Link>
                                        <div className="author-email">{personal_info?.email}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="profile-author-button ms-auto">
                                    <div className="btn add-story-btn me-2">
                                        <label className="fileContainer">
                                            <i className="fas fa-plus"></i>
                                            <span> {t('Upload Story')} {isLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}</span>
                                            <input type="file" accept="image/*"
                                                onChange={fileSelectedHandler} />
                                        </label>

                                    </div>
                                    <div className="btn  edit-profile-btn me-2" onClick={() => toggleModal()}>
                                        <i className="fas fa-pen me-2"></i>
                                        <span>{t('Edit Profile')}</span>
                                    </div>
                                    <div className="global-dropdown">
                                        <div className="dropdown">
                                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"><i
                                                    className="fa-solid fa-ellipsis-vertical"></i></button>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item d-flex align-items-center" to={'/settings'}><i
                                                    className="fa-solid fa-cog"></i>
                                                    {t('Settings')}</Link></li>
                                                <li><Link className="dropdown-item d-flex align-items-center" to={'/contact/' + personal_info?.id}><i
                                                    className="fa-solid fa-eye"></i>
                                                    {t('View As')}</Link></li>
                                                <li><a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => _logout()}><i
                                                    className="fa-solid fa-power-off"></i>{t('Logout')}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div className="col-md-5">
                            <div className="about-text-profile mb-3">
                                <div className="bg-white shadow-sm rounded">
                                    <div className="card-body">
                                        <p className="pb-3 m-0">{personal_info?.bio}</p>
                                        <div className="about-text-profile-list">
                                            <ul className="m-0 ps-0">
                                                {personal_info?.designation ? <li><i className="fa-solid fa-briefcase me-2" />{personal_info?.designation ?? ''} {personal_info?.company_name != "" ? <> at <b>{personal_info?.company_name}</b> </> : ""}</li> : ''}
                                                {personal_info?.city ? <li><i className="fa-solid fa-location-dot me-2" />{personal_info?.city} </li> : ''}
                                                {personal_info?.web_site ? <li><i className="fa-solid fa-globe me-2" /><a target="_blank" href={`https://${personal_info?.web_site}`}>{personal_info?.web_site}</a></li> : ''}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="connection-count-profile mb-3">
                                <div className="bg-white shadow-sm rounded">
                                    <div className="card-body">
                                        <div className="connection-count-meta pb-2">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="connection-count-heading-txt">
                                                    <h6 className="p-0 mb-0">{t('Connections')}</h6>
                                                    <p>{personal_info?.total_connects} {t('Contacts')}</p>
                                                </div>
                                                <div className="view-text">
                                                    <Link to="/contacts">{t('View all')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            {progress && demo_data?.map((post, i) => (
                                                <Placeholder1 height={200} width={100} key={i} backgroundColor="#ffffff"
                                                    foregroundColor="#ecebeb" h />
                                            ))}
                                            {!progress && personal_info?.friend_list?.length > 0 ?
                                                personal_info?.friend_list.slice(0, 6)?.map((user, index) => (
                                                    <div key={index} className="col-sm-6 col-6 col-md-4">
                                                        <div className="bg-white connection-profile-img-meta rounded pb-2 pt-2">

                                                            <div className="connection-profile-img-container rounded-circle"
                                                                style={{ background: `url(${user.avatar})` }} />
                                                            <p className="connection-profile-author-name"><Link to={'/contact/' + user.user_id}>{user.name}</Link></p>
                                                        </div>
                                                    </div>
                                                ))
                                                : ''
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-img-profile mb-3">
                                <div className="bg-white shadow-sm rounded">
                                    <div className="card-body">
                                        <div className="view-img-profile-meta pb-2">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="view-img-profile-heading-txt">
                                                    <h6 className="p-0 mb-0">{t('Photos')}</h6>
                                                </div>
                                                <div className="view-text">
                                                    {/* <a href>View all</a> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            {progress && demo_data?.map((post, i) => (
                                                <Placeholder1 height={200} width={100} key={i} backgroundColor="#ffffff"
                                                    foregroundColor="#ecebeb" h />
                                            ))}
                                            {!progress && post_images?.length > 0 ?
                                                post_images?.map((img, l) => (
                                                    <div className="col-sm-6 col-6 col-md-4">
                                                        <div className="view-img-profile-meta rounded">
                                                            <div onClick={() => toggleImageView(post_images, l)} className="view-img-profile-container rounded clickable"
                                                                style={{ background: `url(${img.image})` }} />
                                                        </div>
                                                    </div>
                                                ))

                                                : ''}



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">

                            {/* <div className="profile-menu">
                                <ul className="d-flex justify-content-between profile-menu-item">
                                    <li><Link to={"#"}>About me</Link></li>
                                    <li><Link to={"#"}>Photos</Link></li>
                                    <li><Link to={"#"}>Video</Link></li>
                                    <li><Link to={"#"}>Friends</Link></li>
                                </ul>
                            </div> */}
                            <CreatePost />

                            {selector?.loading && [1, 2, 3, 4, 5, 6]?.map((post, i) => (
                                <Facebook key={i} backgroundColor="#ffffff"
                                    foregroundColor="#ecebeb" />
                            ))}

                            {!selector?.loading && postsData?.map((post, index) => (
                                <PostNew key={index} data={post} get_post_data={get_post_data} get_post_image_index={get_post_image_index} togglePostView={togglePostView} />

                            ))}

                            {selector?.data.next_page_url != null ?
                                <div className={'text-center'} style={{ marginBottom: '10px' }}>

                                    <button className={'btn btn-sm btn-primary'} onClick={fetchRepositoriesNext}>Load More {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}</button>
                                </div>
                                : ''
                            }
                        </div>

                    </div>
                </div>

                <Modal
                    isOpen={modalDialog}
                    size=""
                    toggle={toggleModal}
                    className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
                >
                    <ModalHeader toggle={toggleModal} id={'staticBackdropLabel'} className="bg-light">
                    {t('Edit Profile')}
                    </ModalHeader>


                    <ModalBody>
                        <div className="meeting-schedule-popup">

                            <div className="form-group mb-1">
                                <label className="form-label" htmlFor={'name'}>{t('Name')}</label>
                                <input 
                                    ref={name} 
                                    name="name" 
                                    id={'name'} 
                                    defaultValue={personal_info?.name} 
                                    type="text"
                                    className="form-control form-control-sm" 
                                    onChange={(e) => handleChange1(e)} 
                                />
                                <small className="text-danger" id="nameError"></small>
                            </div>

                            <div className="form-group mb-1">
                                <label htmlFor={'company_name'} className="form-label">{t('Company Name')}</label>
                                <input 
                                    ref={company_name} 
                                    id={'company_name'} 
                                    name="company_name"
                                    className="form-control form-control-sm" 
                                    defaultValue={personal_info?.company_name} 
                                    key={personal_info?.company_name} 
                                    onChange={(e) => handleChange1(e)} 
                                />
                                <small className="text-danger" id="companyNameError"></small>
                            </div>

                            <div className="form-group mb-1">
                                <label htmlFor={'designation'} className="form-label">Designation</label>
                                <input 
                                    ref={designation} 
                                    id={'designation'} 
                                    name="designation" 
                                    type="text"
                                    className="form-control form-control-sm" 
                                    defaultValue={personal_info?.designation} 
                                    key={personal_info?.designation} 
                                    onChange={(e) => handleChange1(e)} 
                                />
                                <small className="text-danger" id="designationError"></small>
                            </div>

                            <div className="form-group mb-1">
                                <label htmlFor={'bio'} className="form-label">Bio</label>
                                <textarea 
                                    ref={bio} 
                                    id={'bio'} 
                                    name="bio" 
                                    className="form-control form-control-sm" 
                                    defaultValue={personal_info?.bio} 
                                    key={personal_info?.bio} 
                                    rows={4} 
                                    onChange={(e) => handleChange1(e)} 
                                />
                                <small className="text-danger" id="bioError"></small>
                            </div>

                            <div className="form-group mb-1">
                                <label htmlFor={'web_site'} className="form-label">Web Site</label>
                                <input 
                                    ref={web_site} 
                                    id={'web_site'} 
                                    name="web_site" 
                                    type="text"
                                    className="form-control form-control-sm" 
                                    defaultValue={personal_info?.web_site} 
                                    key={personal_info?.web_site} 
                                    onChange={(e) => handleChange1(e)} 
                                />
                                <small className="text-danger" id="websiteError"></small>
                            </div>

                            <div className="form-group mb-1">
                                <label htmlFor={'city'} className="form-label">City</label>
                                <input 
                                    ref={city} 
                                    id={'city'} 
                                    name="city" 
                                    type="text"
                                    className="form-control form-control-sm" 
                                    defaultValue={personal_info?.city} 
                                    key={personal_info?.city} 
                                    onChange={(e) => handleChange1(e)} 
                                />
                                <small className="text-danger" id="cityError"></small>
                            </div>


                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary" onClick={() => _profile_update()} type={'button'}>{t('Update')} {isLoadingUp ? (
                            <span
                                className="spinner-border spinner-border-sm ml-5"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            <span></span>
                        )}
                        </button>

                    </ModalFooter>

                </Modal>

                <CropEasy _profile_image_update={_profile_image_update} photoURL={photoURL} isModalVisible={openCrop} handleShowB={() => setOpenCrop(false)} changePhotoUrl={changePhotoUrl} />

                <CoverCrop coverURL={coverURL} coverShowB={() => setOpenCropCover(false)} isModalVisibleCover={openCropCover} _profile_cover={_profile_cover} />
                <PostViewProfile postId={post_search_Id} isModalVisible={postView_modalDialog} handleShowB={() => setPostViewModalDialog(false)} imageIndex={image_index} />
                <ImageView images={image_array} isModalVisible={imageView_modalDialog} handleShowB={() => setImageViewModalDialog(false)} imageIndex={img_index} />

            </div>


        </div>


    )
}