import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_Notify_FETCH_STOP } from "../Reducer/ActiveNotifyReducer";
import { Link } from "react-router-dom";

export default function Notify(props){
    const [is_show, setShow] = useState(false);
    const selector = useSelector((state) => state.activeNotifyReducer)
    const dispatch=useDispatch();
    const hideMessage=()=>{
      dispatch(ACTIVE_Notify_FETCH_STOP());
    }

    useEffect(() => {
      const timer = setTimeout(() => {
        hideMessage();
      }, 12000);

      return () => {
        clearTimeout(timer);
      };
    }, [selector, dispatch]);

    return (
    <>
     <div className={`popup-notification ${selector?.loading?'d-block':'d-none'}`}>
        <div className="popup-notification-wrap shadow">
          <Link to={
            selector?.data?.type1 == 4 ? `/contact/${selector?.data?.app_url}` : 
            selector?.data?.type1 == 0 ? `/meeting-details/${selector?.data?.app_url}` : 
            selector?.data?.type1 == 6 ? `/meeting-details/${selector?.data?.app_url}` : 
            selector?.data?.type1 == 7 ? `/stories/${selector?.data?.app_url}` :  
            `/post/${selector?.data?.app_url}`
            }
          >
          <div className="popup-notification-body d-flex">
            <div className="popup-notification-user-info">
              <div className="popup-notification-user-info-thamb">
                <div className="image">
                  <a href="javascript:void(0)">
                    <div className="user-picture-container rounded-circle" style={{background: `url("${selector?.data?.avatar}")`}} />
                  </a>
                  <div className="popup-notification-reaction">
                    <div className="popup-notification-reaction-icon">
                      <i className="fa-solid fa-thumbs-up" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="popup-notification-content">
              <p className="mb-2 pr-2 fw-bolder">
                {selector?.data?.title}
              </p>
              <p className="mb-2">{selector?.data?.details}</p>
              <small className="text-primary">A few seconds ago</small>
            </div>
          </div>
          </Link>
          <div className="popup-notification-header">
            <div className="popup-notification-header-close-btn">
              <a href="javascript:void(0)" onClick={()=>hideMessage()}><i className="fa-solid fa-xmark"/></a>
            </div>
          </div>
        </div>
      </div>
    </>
    );
}